import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import { ThemeModeContext, isDark } from '../../providers/ThemeModeProvider';
import { SearchIcon } from '../../icons';
import { HiddenLabel } from '../HiddenLabel';
import { TrackedLink } from '../TrackedLink';
import { SearchIconButtonProps } from './SearchIconButton.types';

interface SearchIconComponentProps {
	top: string;
}

const SearchIconComponent = styled(SearchIcon)<SearchIconComponentProps>`
	overflow: hidden;
	position: absolute;
	margin: 0 0 0 5px;
	top: ${({ top }) => top}

	&:hover {
		opacity: 0.8;
	}
`;

export const SearchIconButton: FC<React.PropsWithChildren<SearchIconButtonProps>> = ({
	tracking,
	to,
	inverted = false,
	children,
	top = '5px',
}) => {
	const { mode } = useContext(ThemeModeContext);

	return (
		<TrackedLink to={to} tracking={tracking}>
			<SearchIconComponent
				size="19px"
				inverted={isDark(mode) ? !inverted : inverted}
				top={top}
			/>
			<HiddenLabel>{children}</HiddenLabel>
		</TrackedLink>
	);
};
