import { z } from 'zod';

export const IdentitySchema = z.object({
	first_name: z.string().nullable(),
	last_name: z.string().nullable(),
	date_of_birth: z.string().nullable(),
	has_ssn: z.boolean(),
	address: z.string().nullable(),
	address2: z.string().nullable(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	postal_code: z.string().nullable(),
	citizenship_country: z.string().nullable(),
	birth_country: z.string().nullable(),
	permanent_resident: z.string().nullable(),
	visa_type: z.string().nullable(),
	visa_expiration_date: z.string().nullable(),
});

export const BankLinkStateSchema = z
	.enum(['PENDING', 'APPROVED', 'REJECTED', 'CANCELED'])
	.optional();
export type BankLinkState = z.infer<typeof BankLinkStateSchema>;
export const BankLinkSchema = z.object({
	id: z.string().optional(),
	maskedAccountNumber: z.string().nullable(),
	routingNumber: z.string().nullable(),
	institutionName: z.string().nullable(),
	approvalMethod: z
		.enum(['MICRO_DEPOSIT', 'PLAID', 'PLAID_TOKEN', 'QUOVO', 'INTERNAL_BANK'])
		.optional(),
	state: BankLinkStateSchema,
	statusChangedAt: z.coerce.date(),
});

const AccountState = z.enum([
	'incomplete',
	'queued_for_apex',
	'errored',
	'pending',
	'back_office',
	'impeded',
	'indeterminate',
	'unsupported',
	'complete',
	'rejected',
	'in_closing_process',
	'closed',
	'inactive',
	'in_activation_process',
	'in_reopen_process',
]);

export const Account = z.object({
	id: z.string(),
	type: z.string(),
	atlas_id: z.string(),
	state: AccountState,
	short_title: z.string(),
	title: z.string(),
	e_signature_agreed_at: z.coerce
		.date()
		.transform((timestamp) => timestamp.toISOString())
		.optional()
		.nullable(),
	type_title: z.string(),
	identity: IdentitySchema.or(z.null()),
	management_type: z.enum(['SELF_DIRECTED', 'ROBO']),
});

export const AccountsSchema = z.object({
	accounts: z.array(Account),
});

export type Accounts = z.infer<typeof AccountsSchema>;
