import queryString from 'query-string';
import { Box, Tag, Flex } from '@stashinvest/ui';
import {
	TooltipEmoji,
	Name,
	FalseyText,
	SearchIconButton,
	FormattedDate,
	TableFooter,
	CardWithPropTable,
	TrackedLink,
} from '@stashinvest/shared-ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { ACCOUNT_DETAILS } from 'src/constants/routes';
import { UserProfileOrContentOnlyUserProfile } from '@stashinvest/shared-types';
import { formatPhoneNumber } from 'src/utils/displayFormatter';

export type UserDetailsProps = {
	title: string;
	userProfile: UserProfileOrContentOnlyUserProfile;
	userId: string;
	trackingPage: string;
	showQuickSearch?: boolean;
};

export const UserDetails = ({
	title,
	userProfile,
	userId,
	trackingPage,
	showQuickSearch = false,
}: UserDetailsProps) => {
	const {
		info: {
			emailVerified,
			phoneVerified,
			contentOnly: isUserContentOnly,
			email,
			phoneNumber,
		},
		profile: { firstName, lastName, dateOfBirth, ssn },
	} = userProfile;

	const nameParams = queryString.stringify({ firstName, lastName });

	return (
		<Flex maxWidth="529px" width={{ mobile: '100%' }} data-testid="user-details">
			<CardWithPropTable
				card={{ title: title }}
				tracking={{ page: trackingPage, component: 'user-details-card' }}
				table={{
					rows: [
						{
							title: 'Name',
							value: (
								<>
									<Name
										firstName={firstName ?? undefined}
										lastName={lastName ?? undefined}
									/>

									{showQuickSearch && firstName && lastName ? (
										<SearchIconButton
											to={`/search?${nameParams}`}
											tracking={{
												page: 'user',
												component: 'user-details',
												label: 'name-search',
											}}
											top="8px"
										>
											Search for duplicate accounts by first &amp; last name
										</SearchIconButton>
									) : null}
								</>
							),
							testId: 'name',
						},
						{
							title: 'DOB',
							value: (
								<>
									{isUserContentOnly && !ssn ? (
										<Text.Label14 fontWeight="bold" as="div" data-testid="error-text">
											***** ** ****
										</Text.Label14>
									) : dateOfBirth ? (
										<FormattedDate timestamp={dateOfBirth} timezone="Etc/UTC" />
									) : (
										<Tag variant="warning">Empty</Tag>
									)}
								</>
							),
							testId: 'dob',
						},
						{
							title: 'SSN',
							value: (
								<>
									<FalseyText text={ssn ?? undefined} />
								</>
							),
							testId: 'ssn',
						},
						{
							title: 'Email',
							value: (
								<>
									<span data-testid="email" style={{ overflowWrap: 'anywhere' }}>
										{isUserContentOnly && !ssn ? '****************' : email}
									</span>
									{email && (
										<TooltipEmoji
											emojiLabel={emailVerified ? 'Email verified' : 'Unverified'}
											description={emailVerified ? 'Email verified' : 'Unverified'}
											style={{
												position: 'absolute',
												right: 0,
												paddingLeft: '5px',
												top: '6px',
											}}
										>
											{emailVerified ? '✅' : '❓'}
										</TooltipEmoji>
									)}
								</>
							),
						},
						{
							title: 'Phone',
							value: (
								<>
									<FalseyText
										text={
											isUserContentOnly && !ssn
												? '* *** **** **'
												: formatPhoneNumber(phoneNumber) ?? undefined
										}
									/>
									{phoneNumber ? (
										<SearchIconButton
											to={`/search?phone=${phoneNumber}`}
											tracking={{
												page: 'user',
												component: 'user-details',
												label: 'phonenumber-search',
											}}
										>
											Search for duplicate accounts by Phone Number
										</SearchIconButton>
									) : null}
									{phoneNumber && (
										<TooltipEmoji
											emojiLabel={phoneVerified ? 'Phone verified' : 'Unverified'}
											description={phoneVerified ? 'Phone verified' : 'Unverified'}
											style={{
												position: 'absolute',
												right: 0,
												paddingLeft: '5px',
												top: '6px',
											}}
										>
											{phoneVerified ? '✅' : '❓'}
										</TooltipEmoji>
									)}
								</>
							),
							testId: 'number',
						},
					],
				}}
			>
				<TableFooter>
					<TrackedLink
						to={ACCOUNT_DETAILS.replace(':userId', userId)}
						tracking={{
							page: 'user',
							component: 'user-profile-card',
							label: 'account-details-link',
						}}
					>
						View Account Details
					</TrackedLink>
				</TableFooter>
			</CardWithPropTable>
		</Flex>
	);
};
